import { Box, HStack, Text } from "@chakra-ui/react";
import {
  LineIcon,
  LineShareButton,
  TwitterIcon,
  TwitterShareButton,
  PinterestIcon,
  PinterestShareButton,
} from "react-share";

export const ShareButtons = ({
  url,
  title = "Monacute",
  hashtag = "monacute",
  imageUrl,
}: {
  url?: string;
  title?: string;
  hashtag?: string;
  imageUrl?: string;
}) => {
  const pageUrl = url || "https://monacute.art";

  return (
    <Box>
      <HStack spacing={2}>
        <TwitterShareButton
          url={pageUrl}
          title={title}
          via="monacute_art"
          related={["monacute_art"]}
          hashtags={[hashtag]}
        >
          <TwitterIcon size={50} round />
        </TwitterShareButton>

        <LineShareButton url={pageUrl} title={title}>
          <LineIcon size={50} round />
        </LineShareButton>

        {imageUrl && (
          <PinterestShareButton
            url={pageUrl}
            media={imageUrl}
            description={title}
          >
            <PinterestIcon size={50} round />
          </PinterestShareButton>
        )}
      </HStack>
    </Box>
  );
};
