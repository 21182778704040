import React from "react";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { HomePage } from "./page";

const antiAliasText = {
  baseStyle: {
    transform: "rotate(0.03deg)",
  },
};

const theme = extendTheme({
  // デフォルトのフォント
  // https://chakra-ui.com/docs/theming/theme#typography
  fonts: {
    body: "'M PLUS 1p', sans-serif",
    heading: "'M PLUS 1p', sans-serif",
    mono: "Menlo, monospace",
  },
  // デフォルトのカラーモード
  // https://chakra-ui.com/docs/theming/theme#config
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  fontWeights: {
    thin: 300,
    light: 300,
    normal: 400,
    medium: 400,
    bold: 700,
  },
  components: {
    Text: antiAliasText,
    Heading: antiAliasText,
    Button: antiAliasText,
    Link: {
      baseStyle: {
        _focus: {
          boxShadow: "0px 0px 0px 2px #F687B3",
        },
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          _focus: {
            boxShadow: "0px 0px 0px 3px #F687B3",
          },
        },
      },
    },
    Radio: {
      baseStyle: {
        control: {
          _focus: {
            boxShadow: "0px 0px 0px 3px #F687B3",
          },
        },
      },
    },
  },
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <HomePage />
    </ChakraProvider>
  );
}

export default App;
