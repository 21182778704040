import { Box, Center, Heading, Link, Text } from "@chakra-ui/react";

const CopyRight = ({ year, name }: { year: string; name: string }) => {
  return (
    <Text fontSize="sm" color="gray.500">
      &copy; {year} {name}
    </Text>
  );
};
const FootLink = ({
  href,
  children,
}: {
  href: string;
  children: React.ReactNode;
}) => (
  <Link href={href} fontSize="sm" ml={1} mr={1}>
    {children}
  </Link>
);

export const LayoutFooter = () => {
  return (
    <>
      <Center mt={4}>
        <CopyRight year="2021" name="Monacute" />
      </Center>
      <Center mb={2}>
        <FootLink href="https://twitter.com/palon7">made by @palon7</FootLink> /
        <FootLink href="/privacy.html">プライバシーポリシー</FootLink>
      </Center>
    </>
  );
};
