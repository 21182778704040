import {
  Center,
  Heading,
  VStack,
  Image,
  Flex,
  Button,
  HStack,
  Text,
  Stack,
  Link,
  Container,
  Box,
  Spacer,
} from "@chakra-ui/react";
import React from "react";
import { useEffect, useState } from "react";
import { TwitterIcon } from "react-share";
import Layout from "../components/common/layout";
import { LayoutFooter } from "../components/common/layout_footer";
import { LayoutHeader } from "../components/common/layout_header";
import { PaperImagePanel } from "../components/parts/PaperImagePanel";
import { ShareButtons } from "../components/parts/ShareButton";
import { motion } from "framer-motion";
import { ChevronDownIcon } from "@chakra-ui/icons";

import { Link as Scroll } from "react-scroll";

const MotionBox = motion(Box);
const MotionText = motion(Text);

const PointBox = ({
  title,
  image,
  children,
}: {
  title: string;
  image: string;
  children: React.ReactNode;
}) => {
  return (
    <PaperImagePanel image="/img/ogp.png">
      <Heading as="h3" size="lg" fontWeight="bold" mb={4}>
        {title}
      </Heading>
      {children}
    </PaperImagePanel>
  );
};

export const useWindowDimensions = () => {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    const onResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    onResize();
    //window.addEventListener("resize", onResize);
    //return () => window.removeEventListener("resize", onResize);
  }, []);
  return windowDimensions;
};

const AnimBox = ({
  children,
  delay = 0,
  duration = 1,
  x = -100,
  y = 0,
  ...props
}: {
  children: React.ReactNode;
  delay?: number;
  duration?: number;
  x?: number;
  y?: number;
  [key: string]: any;
}) => {
  return (
    <MotionBox
      initial={{ x, y, opacity: 0 }}
      animate={{ x: 0, y: 0, opacity: 1 }}
      transition={{
        duration: duration,
        ease: "easeOut",
        delay: delay,
      }}
      {...props}
    >
      {children}
    </MotionBox>
  );
};

const FollowButton = ({
  text = "Twitterでフォローする",
  ...props
}: {
  text?: string;
  [key: string]: any;
}) => (
  <Button
    as="a"
    href="https://twitter.com/monacute_art"
    colorScheme="white"
    variant="outline"
    size="lg"
    {...props}
  >
    {text}
  </Button>
);

const MainBox = () => (
  <Box color="white">
    <Center>
      <AnimBox>
        <Heading fontSize="4xl" fontWeight="normal" mt={4} lineHeight={1}>
          Monacute
        </Heading>
      </AnimBox>
    </Center>
    <VStack spacing="4px" mb={8} mt={1}>
      <AnimBox delay={0.5}>
        <Text>AIが描く、とってもキュートなNFT。</Text>
      </AnimBox>

      <AnimBox delay={1.5}>
        <Text
          pt={10}
          pb={1}
          fontSize="3xl"
          textAlign="center"
          fontWeight="thin"
        >
          2022.03.15
        </Text>
        <Text pt={0} lineHeight={0} pb={8} fontSize="sm" textAlign="center">
          for Monaparty
        </Text>
      </AnimBox>

      <AnimBox delay={2.7} x={-200} duration={1}>
        <FollowButton />
      </AnimBox>
    </VStack>
    <AnimBox delay={3.7} x={0} y={500}>
      <Center>
        <Scroll to="about" smooth>
          <Link as="a">
            <ChevronDownIcon w={8} h={8} />
          </Link>
        </Scroll>
      </Center>
    </AnimBox>
  </Box>
);

const ScrollSection = ({
  children,
  delay = 0,
}: {
  children: React.ReactNode;
  delay?: number;
}) => (
  <MotionBox
    justifyContent="center"
    alignItems="center"
    initial={{ opacity: 0 }}
    whileInView={{ opacity: 1 }}
    viewport={{ once: true, amount: 0.8 }}
    transition={{
      duration: 1,
      ease: "easeOut",
      delay: delay,
    }}
  >
    {children}
  </MotionBox>
);

const DescTitle = ({
  children,
  id,
}: {
  children: React.ReactNode;
  id?: string;
}) => (
  <ScrollSection>
    <Center mt={6} mb={3}>
      <Heading
        as="h2"
        fontSize="4xl"
        fontWeight="bold"
        mt={4}
        id={id}
        textAlign="center"
      >
        {children}
      </Heading>
    </Center>
  </ScrollSection>
);

const DescBody = ({
  children,
  delay = 0,
  px = 4,
}: {
  children: React.ReactNode;
  delay?: number;
  px?: number;
}) => (
  <ScrollSection delay={delay}>
    <Center mb={6} px={px}>
      <Text fontSize="md" fontWeight="normal" mt={4}>
        {children}
      </Text>
    </Center>
  </ScrollSection>
);

const InfoBox = () => (
  <Center>
    <Box maxWidth={800} lineHeight={6}>
      <DescTitle id="about">
        AIが描き出す、<br></br>世界で一つだけのイラスト。
      </DescTitle>
      <DescBody px={0}>
        <Image
          src="/img/banner.png"
          alt="Monacute"
          width="full"
          height={300}
          objectFit="cover"
          overflow="hidden"
        />
      </DescBody>
      <DescTitle id="about">Monacuteとは？</DescTitle>
      <DescBody>
        <p>
          Monacuteは、現在開発中のAIを活用したmonaparty用のNFT（Non-fungible
          token）です。
        </p>
        <p>
          すべてのイラストはAIで自動的に生成され、このサイトでオークションで販売する予定です。
        </p>
        <p>
          特に価値が保証されているわけではありませんが、トークンの所有者はそのとってもキュートな画像・キャラクターに対してあらゆる権利を保有します。
        </p>
      </DescBody>
      <DescTitle id="about">どうやって買うの？</DescTitle>
      <DescBody>
        <p>
          Monacuteは2022/03/15にリリースを予定しており、毎日1つずつダッチ・オークション方式（逆オークション）で販売する予定です。
        </p>
        <p>なお、その際に使用する仮想通貨はMonacoinになります。</p>
      </DescBody>
      <DescBody delay={0.5}>
        <FollowButton
          text="Twitterで最新情報をチェック"
          colorScheme="pink"
          variant="solid"
          size="lg"
        />
      </DescBody>
      <DescBody>
        <Text fontSize="sm">
          <p>
            *
            本サービスは開発中であり、リリースの時期は確実に保障されるものではありません。
          </p>
        </Text>
      </DescBody>
    </Box>
  </Center>
);

export const HomePage = () => {
  const Footer = React.memo(() => <LayoutFooter />);
  const { height } = useWindowDimensions();

  return (
    <>
      <LayoutHeader />
      <main>
        <Box minWidth="100%">
          <Flex
            bgImage="url('/img/bg.png')"
            bgPos={["center", "center"]}
            bgSize="cover"
            bgRepeat="no-repeat"
            h={height}
            justify="center"
            align="center"
          >
            <MainBox />
          </Flex>
        </Box>
        <InfoBox />
        <Center my={8}>
          <ShareButtons />
        </Center>
      </main>
      <Footer />
    </>
  );
};
